import {DeleteFilled, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Checkbox, Descriptions, Form, Input, InputNumber, message, Modal, Select, Space, Spin, Switch, Table} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, AttributeExtractionDefinitionServiceContext, AttributeExtractorServiceContext, VulSourceServiceContext} from "../../Contexts";
import AttributeDefinition from "../../domain/AttributeDefinition";
import AttributeExtractionDefinition from "../../domain/AttributeExtractionDefinition";
import AttributeExtractor, {AttributeExtractorType} from "../../domain/AttributeExtractor";
import TicketCreationDefinition from "../../domain/TicketCreationDefinition";
import VulSource from "../../domain/VulSource";
import {formatVulSourceType, VulSourceType} from "../../domain/VulSourceType";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {useTableHandler} from "../../sal-ui/TableHandler";
import {filterObject, formatDateTime} from "../../utils/FormatUtils";
import * as globalStyles from "../App.module.css";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "./AttributeExtractionDefinitionDetail.module.css";
import {AttributeExtractionSource, formatAttributeExtractionSource} from "../../domain/AttributeExtractionSource";
import {AttributeValueTransformerType} from "../../domain/AttributeValueTransformerType";
import {ColumnsType} from "antd/es/table";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

const extractorServerViolationsHolder = new ServerConstraintViolationsHolder();

function AttributeExtractionDefinitionDetail() {
    const appContext = useContext(AppContextContext);
    const attributeExtractionDefinitionService = useContext(AttributeExtractionDefinitionServiceContext);
    const attributeExtractorService = useContext(AttributeExtractorServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const navigate = useNavigate();
    const {attributeExtractionDefinitionId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [addExtractorMode, setAddExtractorMode] = useState(false);
    const [editExtractorMode, setEditExtractorMode] = useState(false);
    const [attributeExtractionDefinition, setAttributeExtractionDefinition] = useState<AttributeExtractionDefinition>();
    const [attributeExtractor, setAttributeExtractor] = useState<AttributeExtractor>();
    const [editForm] = useForm();
    const [extractorForm] = useForm();
    const [attributeExtractorType, setAttributeExtractorType] = useState<AttributeExtractorType>(AttributeExtractorType.JSON_POINTER);
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();
    const [vulSources, setVulSources] = useState<VulSource[]>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const tableHandler = useTableHandler("priority", {reloadFunction: null});

    const columns: ColumnsType<AttributeExtractor> = [
        {dataIndex: "id", title: "ID", width: 80, align: "center", render: value => <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>},
        {dataIndex: "priority", title: "Priority", width: 80, align: "center"},
        {dataIndex: "extractorType", title: "Extractor type", width: 160, align: "left"},
        {
            title: "Detail", align: "left", render: (value: any, extractor: AttributeExtractor) => (
                <Descriptions column={1} size={"small"} bordered={false} className={styles['extractor-description']}>
                    {(extractor.extractorType === AttributeExtractorType.JSON_POINTER || extractor.extractorType === AttributeExtractorType.JSON_PATH) && Object.keys(filterObject(extractor, ['id', 'extractorType', 'priority'])).map(key => <Descriptions.Item key={key} label={key}>
                        {value[key]}
                    </Descriptions.Item>)}
                </Descriptions>
            )
        },
    ];

    if (appContext.user.isSystemAdmin()) {
        columns.push({title: "Actions", className: globalStyles["table-actions"], width: 140, render: renderAction});
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeDefinitionService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setAttributeDefinitions(value.data);
        });

        vulSourceService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setVulSources(value.data);
        });

        reload();
    }, []);

    return (
        <DocumentTitle title={attributeExtractionDefinition?.id}>
            <>
                <Breadcrumb className={globalStyles.common__breadcrumb}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={`/attributes/definitions`}>Attribute definitions</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link
                        to={`/attributes/definitions/${attributeExtractionDefinition?.attributeDefinition.id}`}>Attribute {attributeExtractionDefinition?.attributeDefinition.name}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Extraction {attributeExtractionDefinition?.id}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!attributeExtractionDefinition} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{attributeExtractionDefinition?.id}</h1>

                    {appContext.user.isSystemAdmin() &&
                        <div className={globalStyles['common__top-button-bar']}>
                            <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                            <Button danger={true}
                                    title={"Delete"}
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Do you really want to delete this extraction definition?",
                                            okText: "Delete",
                                            cancelText: "Cancel",
                                            okButtonProps: {danger: true},
                                            onOk: () => onDeleteConfirm(attributeExtractionDefinition!)
                                        });
                                    }}>Delete</Button>

                            <Button type={"primary"}
                                    icon={<PlusOutlined/>}
                                    onClick={onAddExtractorMode}>Add extractor</Button>
                        </div>
                    }

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        <Descriptions.Item label={"ID"}>{attributeExtractionDefinition?.id}</Descriptions.Item>
                        <Descriptions.Item label={"Extraction source"}>{formatAttributeExtractionSource(attributeExtractionDefinition?.extractionSource)}</Descriptions.Item>
                        <Descriptions.Item label={"Enabled"}><Checkbox checked={attributeExtractionDefinition?.enabled} disabled={true}/></Descriptions.Item>

                        {attributeExtractionDefinition?.sources && attributeExtractionDefinition?.sources.length > 0 &&
                            <Descriptions.Item label={"Sources"}>
                                {attributeExtractionDefinition?.sources.map(value => <div><Link to={`/vul-sources/${value.id}`}>{value.name}</Link></div>)}
                            </Descriptions.Item>
                        }

                        {attributeExtractionDefinition?.sourceTypes && attributeExtractionDefinition?.sourceTypes.length > 0 &&
                            <Descriptions.Item label={"Source types"}>{attributeExtractionDefinition?.sourceTypes.map(formatVulSourceType).join(", ")}</Descriptions.Item>
                        }

                        <Descriptions.Item label={"Attribute definition"}>
                            <Link to={`/attributes/definitions/${attributeExtractionDefinition?.attributeDefinition.id}`}>
                                {attributeExtractionDefinition?.attributeDefinition.name} ({attributeExtractionDefinition?.attributeDefinition.valueType})
                            </Link>
                        </Descriptions.Item>

                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Imported at"}>{formatDateTime(attributeExtractionDefinition?.importedAt)}</Descriptions.Item>}

                        <Descriptions.Item label={"Priority"}>{attributeExtractionDefinition?.priority}</Descriptions.Item>
                    </Descriptions>

                    <h2>Extractors</h2>

                    {renderExtractorForm()}

                    <Table className={styles['extractor-table']}
                           showSorterTooltip={false}
                           loading={tableHandler.loading}
                           dataSource={attributeExtractionDefinition?.extractors}
                           size="middle"
                           onChange={tableHandler.onTableChange}
                           pagination={tableHandler.pagination}
                           rowKey="id"
                           columns={columns}
                    />
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderAction(attributeExtractor: AttributeExtractor) {
        return (
            <Space>
                <Button onClick={() => onEditExtractorMode(attributeExtractor)}>Edit</Button>

                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Do you really want to delete this definition?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteExtractorConfirm(attributeExtractor)
                            });
                        }}/>
            </Space>
        )
    }

    function onAddExtractorMode() {
        setEditExtractorMode(false);
        setAddExtractorMode(!addExtractorMode);
        extractorForm.resetFields()
        setAttributeExtractor(undefined);
    }

    function onEditExtractorMode(extractor: AttributeExtractor) {
        setEditExtractorMode(true);
        setAddExtractorMode(false);
        setAttributeExtractor(extractor);
        extractorForm.setFieldsValue(extractor)
    }

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of attribute extraction definition</h3>

                <Form {...layout} form={editForm}
                      className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`}
                      onFinish={onFinishEdit}>

                    <Form.Item
                        name={["attributeDefinition", "id"]}
                        label={"Attribute definition"}
                        rules={[{required: true, message: "required"}]}>

                        <Select>
                            {attributeDefinitions && attributeDefinitions!.map(attributeDefinition => {
                                return <Select.Option key={attributeDefinition?.name}
                                                      value={attributeDefinition?.id!}>{attributeDefinition?.name}: {attributeDefinition?.valueType}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["sources"]}
                        label={"Vulnerability sources"}
                        rules={[{required: false, message: "required"}]}>

                        <Select
                            mode="multiple"
                            allowClear>
                            {vulSources && vulSources!.map(vulSource => {
                                return <Select.Option key={vulSource?.name}
                                                      value={vulSource?.id!}>{vulSource?.name}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["sourceTypes"]}
                        label={"Source types"}
                        rules={[{required: false, message: "required"}]}>
                        <Select
                            mode="multiple"
                            allowClear
                            options={Object.values(VulSourceType).map(type => ({
                                label: formatVulSourceType(type),
                                value: type
                            }))}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"extractionSource"}
                        label={"Extraction source"}
                        rules={[{required: true, message: "Extraction source is required."}]}>
                        <Select
                            options={Object.values(AttributeExtractionSource).map(source => ({
                                label: formatAttributeExtractionSource(source),
                                value: source
                            }))}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"priority"}
                        label={"Priority"}
                        initialValue={0}
                        rules={[
                            {required: true, message: "Priority is required."},
                        ]}>
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        name={["enabled"]}
                        label={"Enabled"}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderExtractorForm() {
        return (
            <Collapse isOpen={addExtractorMode || editExtractorMode}>
                <h3>{addExtractorMode ? "Add extractor" : "Edit extractor"}</h3>

                <Form {...layout} form={extractorForm} className={`${globalStyles["common__form"]}`}
                      onFinish={onFinishExtractor}>

                    <Form.Item
                        name={"priority"}
                        label={"Priority"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}
                        initialValue={0}>
                        <InputNumber/>
                    </Form.Item>

                    {addExtractorMode && <Form.Item
                        name={["extractorType"]}
                        label={"Type"}
                        rules={[{required: true, message: "required"}]}>
                        <Select onChange={value => setAttributeExtractorType(value)}>
                            <Select.Option key={AttributeExtractorType.JSON_POINTER} value={AttributeExtractorType.JSON_POINTER}>{AttributeExtractorType.JSON_POINTER}</Select.Option>
                            <Select.Option key={AttributeExtractorType.JSON_PATH} value={AttributeExtractorType.JSON_PATH}>{AttributeExtractorType.JSON_PATH}</Select.Option>
                            <Select.Option key={AttributeExtractorType.HIGHEST_SEAL_SCORE_FOR_CVE} value={AttributeExtractorType.HIGHEST_SEAL_SCORE_FOR_CVE}>{AttributeExtractorType.HIGHEST_SEAL_SCORE_FOR_CVE}</Select.Option>
                        </Select>
                    </Form.Item>}

                    {(attributeExtractorType === AttributeExtractorType.JSON_POINTER || attributeExtractorType === AttributeExtractorType.JSON_PATH) &&
                        <>
                            <Form.Item
                                name={"path"}
                                label={"Path"}
                                rules={[
                                    {required: true, message: "Path is required."},
                                    {validator: extractorServerViolationsHolder.createServerValidator('UNKNOWN'), message: "Invalid JSON path."}
                                ]}>
                                <Input maxLength={500}/>
                            </Form.Item>

                            <Form.Item
                                name={"patternMatch"}
                                label={"Pattern match"}
                                rules={[
                                    {required: true, message: "Pattern match is required."},
                                ]}>
                                <Input maxLength={500}/>
                            </Form.Item>

                            <Form.Item
                                name={"patternReplace"}
                                label={"Pattern replace"}
                                rules={[
                                    {required: true, message: "Pattern replace is required."},
                                ]}>
                                <Input maxLength={500}/>
                            </Form.Item>

                            <Form.Item
                                name={"attributeValueTransformerType"}
                                label={"Value transformer"}
                                initialValue={AttributeValueTransformerType.NONE}
                                rules={[{required: true, message: "Value transformer is required."}]}>
                                <Select>
                                    <Select.Option key={AttributeValueTransformerType.NONE} value={AttributeValueTransformerType.NONE}>{AttributeValueTransformerType.NONE}</Select.Option>
                                    <Select.Option key={AttributeValueTransformerType.CVSS_VECTOR_STRING_TO_BASE_SCORE} value={AttributeValueTransformerType.CVSS_VECTOR_STRING_TO_BASE_SCORE}>{AttributeValueTransformerType.CVSS_VECTOR_STRING_TO_BASE_SCORE}</Select.Option>
                                </Select>
                            </Form.Item>
                        </>
                    }

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => {
                            setAddExtractorMode(false);
                            setEditExtractorMode(false);
                        }}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        attributeExtractionDefinitionService.update(attributeExtractionDefinitionId!, values)
            .then(
                () => {
                    message.success("Attribute extraction definition updated.");

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onFinishExtractor(values: any) {
        if (addExtractorMode) {
            attributeExtractionDefinitionService.addExtractor(attributeExtractionDefinition!, values)
                .then(
                    () => {
                        message.success("Attribute extractor added.");

                        setAddExtractorMode(false);

                        reload();
                    },
                    error => extractorServerViolationsHolder.handleServerError(error, extractorForm)
                );

        } else if (editExtractorMode) {
            attributeExtractorService.update(attributeExtractor.id, values)
                .then(
                    () => {
                        message.success("Attribute extrator updated.");

                        setAddExtractorMode(false);

                        setEditExtractorMode(false);

                        reload();
                    },
                    error => extractorServerViolationsHolder.handleServerError(error, extractorForm)
                );
        }
    }

    function onDeleteConfirm(definition: TicketCreationDefinition) {
        attributeExtractionDefinitionService.delete(definition)
            .then(() => {
                message.success("Attribute extraction definition deleted.");

                navigate(`/attributes/definitions/${attributeExtractionDefinition?.attributeDefinition.id}`)
            });
    }

    function onDeleteExtractorConfirm(attributeExtractor: AttributeExtractor) {
        attributeExtractorService.delete(attributeExtractor)
            .then(() => {
                message.success("Attribute extrator deleted.");

                reload();
            });
    }


    function reload() {
        attributeExtractionDefinitionService.get(attributeExtractionDefinitionId!).then(attributeExtractionDefinition => {
            setAttributeExtractionDefinition(attributeExtractionDefinition);

            editForm.setFieldsValue(attributeExtractionDefinition);
            editForm.setFieldValue("sources", attributeExtractionDefinition.sources.map(value => value.id))
        });
    }

}

export default AttributeExtractionDefinitionDetail;
